import request from '@/utils/request'

export function getList(params) {
    return request({
        url: '/api/log/getList',
        method: 'post',
        params: params
    })
}

export function getEquipmentList(params) {
    return request({
        url: '/api/log/getEquipmentList',
        method: 'post',
        params: params
    })
}

export function getOrgin(params) {
    return request({
        url: '/api/log/getOrgin',
        method: 'post',
        params: params
    })
}

export function getTemperatureList(params) {
    return request({
        url: '/api/log/getTemperatureList',
        method: 'post',
        params: params
    })
}

export function getTemperatureEcharts(params) {
    return request({
        url: '/api/log/getTemperatureEcharts',
        method: 'post',
        params: params
    })
}

export function getTemperatureEchartsAverage(params) {
    return request({
        url: '/api/log/getTemperatureEchartsAverage',
        method: 'post',
        params: params
    })
}
// 湿度
export function getHumidityData(params) {
    return request({
        url: '/api/log/getHumidityData',
        method: 'post',
        params: params
    })
}
// 温度
export function getTemperatureData(params) {
    return request({
        url: '/api/log/getTemperatureData',
        method: 'post',
        params: params
    })
}
export function getOperateLogList(params) {
    return request({
        url: '/api/log/getOperateLogList',
        method: 'get',
        params: params
    })
}


/**
 *根据终端用户获取库房列表
 */
export function getLibraryList(params) {
    return request({
        url: '/api/log/getLibraryList',
        method: 'get',
        params: params
    })
}
