<template>
  <div>
    <index path="/log/temperature-echarts-average"></index>
    <div style="position: absolute;right: 1%;width: 81%;top: 1%">
      <div>
        <div class="tools-bar" style="float: right;padding-top: 1vh;padding-bottom: 1vh;font-size: 1.6vh">
          <el-select v-model="enterprise" placeholder="企业" size="mini" style="width:9vw; padding-right: 0.7vw"  @change="getOrginList">
            <el-option
                v-for="item in enterpriseList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select v-model="library" placeholder="库房" size="mini" style="width:9vw; padding-right: 0.7vw" clearable  @change="changeLibrary">
            <el-option
                v-for="item in libraryList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
            >
            </el-option>
          </el-select>
<!--          <el-select v-model="equipment" placeholder="设备" size="mini" style="width:9vw; padding-right: 0.7vw"  @change="search">-->
<!--            <el-option-->
<!--                v-for="item in equipmentList"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.id"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
          <el-date-picker
              style="width: 15vw"
              :clearable="false"
              v-model="date"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
          <el-button style="margin-left: 0.7vw;" size="mini" type="primary" icon="el-icon-search" @click="search">搜索</el-button>

        </div>
      </div>
      <div id="echarts" style="width: 100%;margin-top: 7vh; height: 80vh;"></div>
    </div>
  </div>
</template>

<script>
import index from '../index'

import { getTemperatureEchartsAverage, getEquipmentList, getOrgin, getLibraryList } from '@/api/log'
import echarts from 'echarts'

export default {
  components: {
    index,
  },
  data() {
    return {
      enterpriseList:[], // 企业列表
      equipmentList:[],
      equipment:null,
      enterprise:null,
      date: [],
      count:0,
      library:null, // 选中的库房
      libraryList:[] // 库房列表
    }
  },
  mounted() {
    if(this.$route.query.oid){
      getOrgin().then(res => {
        this.enterprise = parseInt(this.$route.query.oid)
        this.enterpriseList = res.data.data
        getLibraryList({
          orginId:this.enterprise
        }).then(res => {
          this.libraryList = res.data.data
          if (this.libraryList.length > 0) {
            this.library = this.libraryList[0].name
          } else {
            this.library = null
          }
          getEquipmentList({enterprise:this.enterprise,type:3,library:this.library}).then(res => {
            this.equipmentList = res.data.data
            this.equipment = parseInt(this.$route.query.eid)
            this.search()
          })
        })
      })
      return
    }
    // 企业列表
    getOrgin().then(res => {
      this.enterpriseList = res.data.data
      for (let i = 0; i < this.enterpriseList.length; i++) {
        this.enterprise = this.enterpriseList[i].id
        this.getOrginList()
        return
      }
      this.defInit();
    })
  },
  methods: {
    // 选择库房
    changeLibrary(){
      getEquipmentList({enterprise:this.enterprise,type:3,library:this.library}).then(res => {
        this.equipmentList = res.data.data
        for (let i = 0; i < this.equipmentList.length; i++) {
          this.equipment = this.equipmentList[0].id
            if(this.equipment != null){
              this.search()
            }
            return
          }
          this.equipment = null
          this.search()
        })
    },
    // 选择企业
    getOrginList(){
      getLibraryList({
          orginId:this.enterprise
        }).then(res => {
          this.libraryList = res.data.data
          if (this.libraryList.length > 0) {
            this.library = this.libraryList[0].name
          } else {
            this.library = null
          }
          getEquipmentList({enterprise:this.enterprise,type:3,library:this.library}).then(res => {
            this.equipmentList = res.data.data
            for (let i = 0; i < this.equipmentList.length; i++) {
              this.equipment = this.equipmentList[0].id
              if(this.equipment != null){
                this.search()
              }
              return
            }
            this.equipment = null
            this.search()
          })
        })
    },
    // 搜索
    search(){
      this.init()
    },
    // 初始化图表
    init(){
      let option = {
        title: {
          text: '温湿度'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['库房湿度%', '库房温度℃','城市湿度%', '城市温度℃']
        },
        color:['#0466FF','#F88C00','#f16767','#37d00b'],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
        ]
      };
      let begin = this.date && this.date.length > 1 && this.date[0] ? new Date(+new Date(this.date[0]) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') : null;
      let end = this.date && this.date.length > 1 && this.date[1] ? new Date(+new Date(this.date[1]) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') : null;
      getTemperatureEchartsAverage({
        timeBegin:begin,
        timeEnd:end,
        enterprise:this.enterprise,
        // equipment:this.equipment,
        library:this.library
      }).then(res => {
        option.xAxis.data = res.data.data.xAxis.data
        option.series = res.data.data.series
        option.series[0].stack='R'
        option.series[1].stack='C'
        option.series[2].stack='K'
        option.series[3].stack='A'
        var chartDom = document.getElementById('echarts');
        var classificationTrendMyChart = echarts.init(chartDom);
        classificationTrendMyChart.setOption(option);
        window.onresize = function() {
          classificationTrendMyChart.resize();
        }
      })

    },
    defInit(){
        let option = {
          title: {
            text: '温湿度'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['库房湿度%', '库房温度℃','城市湿度%', '城市温度℃']
          },
          color:['#0466FF','#F88C00','#f16767','#37d00b'],
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: []
          },
          yAxis: {
            type: 'value'
          },
          series: [
          ]
        };
        var chartDom = document.getElementById('echarts');
        var classificationTrendMyChart = echarts.init(chartDom);
        classificationTrendMyChart.setOption(option);
        window.onresize = function() {
          classificationTrendMyChart.resize();
        }
      },
    // 获取默认近七天
    getDefaultTime(){
      const start = new Date();
      const end = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      this.date[0] = start
      this.date[1] = end
    }
  },
  created(){
    this.getDefaultTime()
  }
}
</script>

<style scoped>

</style>
