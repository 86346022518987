<template>
  <div style="position: absolute;left: 1%;top: 2%;width: 15%">
    <el-menu
        :default-active="path"
        class="el-menu-vertical-demo"
        router
    >
      <el-menu-item index="/log/equalarm" style=" text-align: center;">
        <span slot="title">设备警情</span>
      </el-menu-item>
      <el-menu-item index="/log/temperature-list" style=" text-align: center;">
        <span slot="title">历史温湿度（列表）</span>
      </el-menu-item>
      <el-menu-item index="/log/temperature-echarts"  style=" text-align: center;">
        <span slot="title">历史温湿度（图表）</span>
      </el-menu-item>
      <el-menu-item index="/log/temperature-echarts-index"  style=" text-align: center;">
        <span slot="title">室内室外温度看板</span>
      </el-menu-item>
      <el-menu-item index="/log/humidity-echarts-index"  style=" text-align: center;">
        <span slot="title">室内室外湿度看板</span>
      </el-menu-item>
      <!-- <el-menu-item index="/log/temperature-echarts-average"  style=" text-align: center;">
        <span slot="title">历史温湿度平均值（图表）</span>
      </el-menu-item> -->
      <el-menu-item index="/log/operate"  style=" text-align: center;">
        <span slot="title">操作日志</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>

export default {
  props:{
    path:String,
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
